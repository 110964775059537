import { InjectionToken } from '@angular/core';

import { LDContext } from 'launchdarkly-js-client-sdk';

/**
 * @whatItDoes Providers contexts for LaunchDarkly.
 *
 * @howToUse
 *
 * ```
 * @Injectable
 * export class CustomLaunchDarklyContextProvider implements LaunchDarklyContextProvider {
 *     constructor() {
 *     }
 *
 *     getProviders(): LDContext {
 *         return {            
 device: {
 key: osName ?? 'device-os',
 kind: 'device',
 anonymous: false,                
 },              
 };
 *     }
 * }
 *
 * @provide
 *{ provide: LAUNCH_DARKLY_CONTEXT_PROVIDER, useClass: CustomLaunchDarklyContextProvider }
 * ```
 *
 * @stable
 */
export interface LaunchDarklyContextProvider {
    getProviders(): Promise<LDContext>;
}

/**
 * @stable
 */
export const LAUNCH_DARKLY_CONTEXT_PROVIDER = new InjectionToken<LaunchDarklyContextProvider>('launch-darkly-context-provider');
